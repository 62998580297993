import React from 'react'
import { Link, useLocation } from "@reach/router";
import { Box, Flex, HStack } from '@chakra-ui/react'

export default function Header() {
  return (
    <Flex p={4} justify='space-between' align='center' bg='gray.700'>
      <HStack spacing={6}>
        <Box>Segue</Box>
        <Link to='/'>Overview</Link>
        <Link to='/wallet'>Wallet</Link>
        <Link to='/token'>Token</Link>
        <Link to='/farms'>Farms</Link>
        <Link to='/analytics'>Analytics</Link>
      </HStack>
      <HStack spacing={6}>
        <Link to='/signin'>Sign In</Link>
        <Link to='/register'>Register</Link>
      </HStack>
    </Flex>
  )
}