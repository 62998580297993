import React from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatNumber, getPriceColor } from "../../utils";
import { differenceInDays, formatDistance } from "date-fns";
import { FiCpu, FiUser } from "react-icons/fi";
import { useAtomValue } from "jotai/utils";
import { tokenState } from "./store";
import { Annotation } from "./shared";

export default function TopHolders() {
  let { topHoldersTXs } = useAtomValue(tokenState);
  if (!topHoldersTXs) return null;

  return (
    <Box rounded="md" bg="rgb(255 255 255 / 5%)">
      <Heading p={4} size="md" bg="rgb(0 0 0 / 5%)" color="gray.300">
        Top Token Holders
      </Heading>
      <Box width="100%" overflow="auto">
        <Table size="xs" variant="striped" className='with-glass'>
          <Thead>
            <Tr>
              <Th>Address</Th>
              <Th isNumeric>Balance</Th>
              <Th isNumeric>Wallet Age</Th>
              <Th isNumeric>Max. Amount In</Th>
              <Th isNumeric>Max. Amount Out</Th>
              <Th isNumeric>Gross TXs</Th>
              <Th>TXs</Th>
              <Th isNumeric>Avg. Daily TXs</Th>
              <Th isNumeric>Duration</Th>
              <Th>First In</Th>
              <Th>Last Out</Th>
              <Th>First In ($)</Th>
              <Th>Last Out ($)</Th>
              <Th isNumeric>%</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="xs">
            {topHoldersTXs?.map((row) => {
              let duration = differenceInDays(
                row.outs ? new Date(row.outs.last_out) : Date.now(),
                new Date(row.ins.first_in),
              );

              let pct = row.outs && row.ins.firstInUSD > 0
                ? 100 * (row.outs?.lastOutUSD / row.ins.firstInUSD)
                : "-";

              return (
                <Tr key={row.address} _hover={{ bg: "gray.600" }}>
                  <Td minW={150}>
                    <Link
                      ml={1}
                      href={`https://etherscan.io/address/${row.address}`}
                      target="_blank"
                    >
                      <Annotation who={row.ins.receiver} />
                    </Link>
                  </Td>
                  <Td isNumeric>
                    <Text fontSize="sm">{"$" + formatNumber(row.value)}</Text>
                    <Text>{formatNumber(row.balance)}</Text>
                    <Text>{row.share + "%"}</Text>
                  </Td>
                  <Td isNumeric>
                    {formatDistance(new Date(row.ins.first_in), Date.now())}
                  </Td>
                  <Td isNumeric>
                    <Text>{formatNumber(row.ins.max_amount)}</Text>
                    <Text>{row.ins.max_date}</Text>
                  </Td>
                  <Td isNumeric>
                    <Text>{formatNumber(row.outs?.max_amount)}</Text>
                    <Text>{row.outs?.max_date}</Text>
                  </Td>
                  <Td isNumeric>
                    <Text>
                      {formatNumber(row.ins.sum + (row.outs?.sum || 0))}
                    </Text>
                    <Text>
                      {formatNumber(row.ins.txs + (row.outs?.txs || 0), 0) +
                        " TXs"}
                    </Text>
                  </Td>
                  <Td>
                    <Flex justify='space-between'>
                      <Text as="span" color="green.200" mr={1}>
                        {formatNumber(row.ins.sum)}
                      </Text>

                      <Text as="span" color="red.300" ml={1}>
                        {row.outs ? formatNumber(row.outs.sum) : "-"}
                      </Text>
                    </Flex>
                    <Flex justify='space-between'>
                      <Text as="span" color="green.300">
                        {row.ins.txs}
                      </Text>
                      <Text as="span" color="red.300">
                        {row.outs?.txs || "-"}
                      </Text>
                    </Flex>
                  </Td>
                  <Td isNumeric>
                    {(
                      (row.ins.txs + (row.outs?.txs || 0)) /
                      (duration || 1)
                    ).toFixed(1)}
                  </Td>
                  <Td isNumeric>{duration} days</Td>
                  <Td isNumeric>{row.ins.first_in}</Td>
                  <Td isNumeric>{row.outs?.last_out}</Td>
                  <Td isNumeric>{formatNumber(row.ins?.firstInUSD)}</Td>
                  <Td isNumeric>{formatNumber(row.outs?.lastOutUSD)}</Td>
                  <Td isNumeric color={pct !== "-" ? getPriceColor(pct) : ""}>
                    {pct !== "-" ? formatNumber(pct) : pct}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
