import React from "react";
import { Box, Button } from "@chakra-ui/react";
import ky from "ky";
import { queryBitcoinSingle } from "../APIs";

async function fetchTopWallets(limit = 10) {
  if (localStorage.getItem("bitcoin")) {
    return JSON.parse(localStorage.getItem("bitcoin"));
  }
  try {
    let { data } = await ky(
      `https://api.blockchair.com/bitcoin/addresses?limit=${limit}`,
    ).json();
    localStorage.setItem("bitcoin", JSON.stringify(data));
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

async function fetchData() {
  let data = await fetchTopWallets(20);
  let result = await queryBitcoinSingle(data[0].address);
  console.log(result);
}

export default function Bitcoin() {
  return (
    <Box>
      <Button onClick={fetchData}>FetchData</Button>
      {}
    </Box>
  );
}
