import React from "react";
import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { formatNumber } from "../../utils";
import { useAtomValue } from "jotai/utils";
import { tokenState } from "./store";

export default function CardStats() {
  let { stats } = useAtomValue(tokenState);
  if (!stats) return null;

  return (
    <Box rounded="md" className='with-glass'>
      <Heading p={4} size="md" bg="rgb(0 0 0 / 5%)" color="gray.300">
        Transfer stats
      </Heading>
      <VStack p={4} spacing={4} align="flex-start">
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Age
          </Text>
          <Text>{stats.age} days</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            First transfer on
          </Text>
          <Text>{stats.first}</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Last transfer on
          </Text>
          <Text>{stats.last}</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Holders
          </Text>
          <Text>{formatNumber(stats.holdersCount, 0)}</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Total TXs
          </Text>
          <Text>{formatNumber(stats.count, 0)}</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Total Unique Senders{" "}
          </Text>
          <Text>{formatNumber(stats.senders_count, 0)}</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Total Unique Receivers{" "}
          </Text>
          <Text>{formatNumber(stats.receivers_count, 0)}</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Total Amount{" "}
          </Text>
          <Text>{formatNumber(stats.amount)}</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Median Amount{" "}
          </Text>
          <Text>{formatNumber(stats.median)}</Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Average Amount{" "}
          </Text>
          <Text>{formatNumber(stats.average)}</Text>
        </HStack>
      </VStack>
    </Box>
  );
}
