import React from "react";
import { Box, Heading, HStack, Link, Text, VStack } from "@chakra-ui/react";
import { formatNumber, getPriceColor } from "../../utils";
import { formatUnits } from "@ethersproject/units";
import { tokenState } from "./store";
import { useAtomValue } from "jotai/utils";

export default function CardInfo() {
  let { info } = useAtomValue(tokenState);
  if (!info) return null;

  return (
    <Box rounded="md" className='with-glass'>
      <Heading p={4} size="md" bg="rgb(0 0 0 / 5%)" color="gray.300">
        {info.name} ({info.symbol}) Information
      </Heading>
      <VStack p={4} spacing={4} align="flex-start">
        <HStack w="100%" spacing={4}>
          <Link href={info.website}>Website</Link>
          <Link href={`https://twitter.com/${info.twitter}`}>Twitter</Link>
          <Link href={`https://etherscan.io/token/${info.address}`}>
            Etherscan
          </Link>
          <Link href={`https://www.coingecko.com/en/coins/${info.coingecko}`}>
            Coingecko
          </Link>
        </HStack>

        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Price
          </Text>
          <Box>
            <Text>{"$" + formatNumber(info.price.rate, 4)}</Text>
            <HStack spacing={4}>
              <Box>
                <Text as="span" fontSize="xs">
                  24h
                </Text>
                <Text
                  as="span"
                  ml={1}
                  textColor={getPriceColor(info.price.diff, 0)}
                >
                  {formatNumber(info.price.diff, 2) + "%"}
                </Text>
              </Box>
              <Box>
                <Text as="span" fontSize="xs">
                  7d
                </Text>
                <Text
                  as="span"
                  ml={1}
                  textColor={getPriceColor(info.price.diff7d, 0)}
                >
                  {formatNumber(info.price.diff7d, 2) + "%"}
                </Text>
              </Box>
              <Box>
                <Text as="span" fontSize="xs">
                  30d
                </Text>
                <Text
                  as="span"
                  ml={1}
                  textColor={getPriceColor(info.price.diff30d, 0)}
                >
                  {formatNumber(info.price.diff30d, 2) + "%"}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Volume
          </Text>
          <Box>
            <Text>{"$" + formatNumber(info.price.volume24h, 0)}</Text>
            <HStack spacing={4}>
              <Box>
                <Text as="span" fontSize="xs">
                  24h
                </Text>
                <Text
                  as="span"
                  ml={1}
                  textColor={getPriceColor(info.price.volDiff1, 0)}
                >
                  {formatNumber(info.price.volDiff1, 2) + "%"}
                </Text>
              </Box>
              <Box>
                <Text as="span" fontSize="xs">
                  7d
                </Text>
                <Text
                  as="span"
                  ml={1}
                  textColor={getPriceColor(info.price.volDiff7, 0)}
                >
                  {formatNumber(info.price.volDiff7, 2) + "%"}
                </Text>
              </Box>
              <Box>
                <Text as="span" fontSize="xs">
                  30d
                </Text>
                <Text
                  as="span"
                  ml={1}
                  textColor={getPriceColor(info.price.volDiff30, 0)}
                >
                  {formatNumber(info.price.volDiff30, 2) + "%"}
                </Text>
              </Box>
            </HStack>
          </Box>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Market Cap
          </Text>
          <Text>
            <Text as="span">
              {"$" + formatNumber(info.price.marketCapUsd, 2)}
            </Text>
          </Text>
        </HStack>
        <HStack w="100%">
          <Text w="40%" color="gray.200">
            Total Supply
          </Text>

          <Box>
            <Text>
              {formatNumber(formatUnits(info.totalSupply, info.decimals), 0)}
            </Text>
            <Text fontSize="xs">
              {"$" +
                formatNumber(
                  Number(formatUnits(info.totalSupply, info.decimals)) *
                    info.price.rate,
                  2
                )}
            </Text>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
}
