import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Heading, HStack, Input, Text, Button } from "@chakra-ui/react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { FiZap } from "react-icons/fi";
import {
  fetchFarms,
  fetchPoolStats,
  fetchProtocolBalances,
  fetchStakedBalances,
  fetchVaultStats,
} from "../APIs";
import { formatNumber } from "../utils";
import GridTable from "@nadavshaar/react-grid-table";
import { useDebounce } from "react-use";

async function fetchBalances(address) {
  //for (let n of ["ethereum", "polygon", "binance-smart-chain"]) {
  let stackedBalances = await Promise.all(
    ["gauge", "masterchef", "geyser", "single-staking"].map((t) =>
      fetchStakedBalances(address, t, "ethereum")
    ),
  );
  //}

  return stackedBalances.flatMap((b) => b[address.toLowerCase()].map((a) => a));
}

async function fetchPoolBalances(address) {
  //for (let n of ["ethereum", "polygon", "binance-smart-chain"]) {
  let balances = await Promise.all(
    ["convex", "curve", "pickle"].map((t) =>
      fetchProtocolBalances(address, t, "ethereum")
    ),
  );
  //}

  return balances.flatMap((b) =>
    b[address.toLowerCase()].products.flatMap((p) => p.assets)
  );
}

async function fetchAllFarms() {
  //for (let n of ["ethereum", "polygon", "binance-smart-chain"]) {
  let farms = await Promise.all(
    ["gauge", "masterchef", "geyser", "single-staking"].map((t) =>
      fetchFarms(t, "ethereum")
    ),
  );
  //}
  return farms.flatMap((f) => f);
}

async function fetchAllPools() {
  //for (let n of ["ethereum", "polygon", "binance-smart-chain"]) {
  let pools = await Promise.all([
    fetchPoolStats("curve", "ethereum"),
    fetchVaultStats("convex", "ethereum"),
    fetchVaultStats("pickle", "ethereum"),
  ]);
  //}
  return pools.flatMap((f) => f);
}

function TableFarms({ farms }) {
  if (!farms) return null;

  let table = useMemo(() => {
    return {
      rows: farms.filter((f) => f.valueLockedUSD > 99999),
      columns: [
        {
          id: 1,
          label: "Name",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner">
              <Text>{data.label}</Text>
              <Text fontSize="xs">{data.protocolDisplay}</Text>
            </div>
          ),
        },
        {
          id: 2,
          label: "Reward Tokens",
          cellRenderer: ({ data }) => (
            <HStack className="rgt-cell-inner" spacing={2}>
              {data.rewardTokens.map((t) =>
                <Text key={t.symbol} fontSize="sm" as="span">
                  {`${t.symbol} (${formatNumber(t.price, 2)})`}
                </Text>
              )}
            </HStack>
          ),
        },
        {
          id: 3,
          field: "dailyROI",
          label: "Daily ROI (%)",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner" style={{ textAlign: "right" }}>
              {formatNumber(data.dailyROI * 100, 4)}
            </div>
          ),
        },
        {
          id: 4,
          label: "Weekly ROI (%)",
          field: "weeklyROI",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner" style={{ textAlign: "right" }}>
              {formatNumber(data.weeklyROI * 100, 4)}
            </div>
          ),
        },
        {
          id: 5,
          label: "Yearly ROI (%)",
          field: "yearlyROI",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner" style={{ textAlign: "right" }}>
              {formatNumber(data.yearlyROI * 100, 4)}
            </div>
          ),
        },
        {
          id: 6,
          label: "TVL ($)",
          field: "valueLockedUSD",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner" style={{ textAlign: "right" }}>
              {formatNumber(data.valueLockedUSD, 2)}
            </div>
          ),
        },
      ],
    };
  }, [farms]);

  return <GridTable
    showSearch={false}
    columns={table.columns}
    rows={table.rows}
  />;
}

function TablePools({ pools }) {
  let table = useMemo(() => {
    return {
      rows: pools?.filter((f) => f.liquidity > 99999),
      columns: [
        {
          id: 1,
          label: "Name",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner">
              <Text>{data.label}</Text>
              <Text fontSize="xs">{data.protocolDisplay}</Text>
            </div>
          ),
        },
        {
          id: 2,
          label: "Tokens",
          cellRenderer: ({ data }) => (
            <HStack className="rgt-cell-inner" wrap="wrap" spacing={2}>
              {data.tokens.map((t) =>
                <Text key={t.symbol} fontSize="xs" as="span">
                  {`${t.symbol} (${formatNumber(t.price, 2)})`}
                </Text>
              )}
            </HStack>
          ),
        },
        {
          id: 3,
          field: "dailyROI",
          label: "ROI (%)",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner" style={{ textAlign: "right" }}>
              <Text fontSize="sm">
                Daily: {formatNumber(data.dailyROI * 100, 4)}
              </Text>
              <Text fontSize="sm">
                Weekly: {formatNumber(data.weeklyROI * 100, 4)}
              </Text>
              <Text fontSize="sm">
                Yealy: {formatNumber(data.yearlyROI * 100, 4)}
              </Text>
            </div>
          ),
        },
        {
          id: 4,
          label: "Volume",
          field: "volume",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner" style={{ textAlign: "right" }}>
              {formatNumber(data.volume, 2)}
            </div>
          ),
        },
        {
          id: 5,
          label: "Fee Volume",
          field: "feeVolume",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner" style={{ textAlign: "right" }}>
              {formatNumber(data.feeVolume, 2)}
            </div>
          ),
        },
        {
          id: 6,
          label: "Liquidity ($)",
          field: "liquidity",
          cellRenderer: ({ data }) => (
            <div className="rgt-cell-inner" style={{ textAlign: "right" }}>
              {formatNumber(data.liquidity, 2)}
            </div>
          ),
        },
      ],
    };
  }, [pools]);

  if (!pools) return null;

  return <GridTable
    showSearch={false}
    columns={table.columns}
    rows={table.rows}
  />;
}

function TableBalances({ balances }) {
  if (!balances) return null;

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Tokens</Th>
          <Th>Reward Tokens</Th>
          <Th isNumeric>Balance ($)</Th>
          <Th isNumeric>% Share</Th>
          <Th isNumeric>ROI (%)</Th>
          <Th isNumeric>TVL ($)</Th>
        </Tr>
      </Thead>
      <Tbody>
        {balances.map((b) => (
          <Tr key={b.label}>
            <Td>
              <Text>{b.label}</Text>
              <Text fontSize="sm">{b.protocolDisplay}</Text>
            </Td>
            <Td fontSize="sm">
              {b.tokens.map((t) =>
                <Text key={t.symbol}>
                  {`${t.symbol} (${t.balanceUSD.toFixed(2)})`}
                </Text>
              )}
            </Td>
            <Td fontSize="sm">
              {b.rewardTokens.map((t) =>
                <Text key={t.symbol}>
                  {`${t.symbol} (${t.balanceUSD.toFixed(2)})`}
                </Text>
              )}
            </Td>
            <Td isNumeric>{formatNumber(b.balanceUSD)}</Td>
            <Td isNumeric>{formatNumber(b.share * 100, 4)}</Td>
            <Td fontSize="sm" isNumeric>
              <Text>Daily: {formatNumber(b.dailyROI * 100, 4)}</Text>
              <Text>Weekly: {formatNumber(b.weeklyROI * 100, 4)}</Text>
              <Text>Yealy: {formatNumber(b.yearlyROI * 100, 4)}</Text>
            </Td>
            <Td isNumeric>{formatNumber(b.valueLockedUSD)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

function TablePoolBalances({ balances }) {
  if (!balances) return null;

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Tokens</Th>
          <Th isNumeric>Balance ($)</Th>
          <Th isNumeric>Share</Th>
          <Th isNumeric>APY</Th>
        </Tr>
      </Thead>
      <Tbody>
        {balances.map((b) => (
          <Tr key={b.label}>
            <Td>
              <Text>{b.label}</Text>
              <Text fontSize="sm">{b.protocolDisplay}</Text>
            </Td>
            <Td fontSize="sm">
              {b.tokens?.map((t) =>
                <Text key={t.symbol}>
                  {`${t.symbol} (${t.balanceUSD.toFixed(2)})`}
                </Text>
              )}
            </Td>
            <Td isNumeric>{formatNumber(b.balanceUSD)}</Td>
            <Td isNumeric>{formatNumber(b.share, 4)}</Td>
            <Td fontSize="sm" isNumeric>
              <Text>{formatNumber(b.apy, 4)}</Text>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default function Lab() {
  const [address, setAddress] = useState(
    "0x006cc1b89e9b68e08eec14a514d17b47b363acce",
  );
  const [isLoading, toggleLoading] = useState();
  const [state, setState] = useState();

  async function doFetch() {
    if (localStorage.getItem(address+"-farming")) {
      setState(JSON.parse(localStorage.getItem(address+"-farming")));
      return;
    }

    toggleLoading(true)

    try {
      let balances = await fetchBalances(address);
      globalThis.balances = balances;

      let poolBalances = await fetchPoolBalances(address);
      globalThis.poolBalances = poolBalances;

      let farms = await fetchAllFarms();
      globalThis.farms = farms;

      let pools = await fetchAllPools();
      globalThis.pools = pools;

      localStorage.setItem(
        address+"-farming",
        JSON.stringify({
          balances,
          farms,
          pools,
          poolBalances,
        }),
      );

      setState({
        balances,
        farms,
        pools,
        poolBalances,
      });

      toggleLoading(false)
    } catch (error) {
      alert(error.message);
      console.error(error);
      setState(null)
      toggleLoading(false)
    }
  }

  return (
    <Box minH="100vh" p={4}>
      <HStack spacing={4}>
        <Heading w="50%" size="lg">
          Farming
        </Heading>
        <HStack w="50%" spacing={4}>
          <Input value={address} onChange={(e) => setAddress(e.target.value)} />
          <Button
            variant="brand"
            leftIcon={<FiZap />}
            isLoading={isLoading}
            onClick={() => doFetch(address)}
          >
            Audit
          </Button>
        </HStack>
      </HStack>

      <Box mt={8}>
        <Tabs colorScheme="brand">
          <TabList>
            <Tab>Portfolio</Tab>
            <Tab>Yield Farms</Tab>
            <Tab>Liquidity Pools</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Heading size="md" mb={6}>Yield Farms</Heading>
              <TableBalances balances={state?.balances} />

              <Heading size="md" my={6}>Liquidity Pools</Heading>
              <TablePoolBalances balances={state?.poolBalances} />
            </TabPanel>
            <TabPanel>
              <TableFarms farms={state?.farms} />
            </TabPanel>
            <TabPanel>
              <TablePools pools={state?.pools} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
