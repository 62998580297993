import React from "react";
import {
  Box,
  Heading,
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatNumber } from "../../utils";
import { formatDistanceToNow } from "date-fns";
import { useAtomValue } from "jotai/utils";
import { tokenState } from "./store";
import { Annotation } from "./shared";

function Distribution({ data, header, title }) {
  if (!data) return null;

  return (
    <Box rounded="md" bg="rgb(255 255 255 / 5%)">
      <Heading p={4} size="md" bg="rgb(0 0 0 / 5%)" color="gray.300">
        {title}
      </Heading>
      <Box w="100%">
        <Table size="xs" className='with-glass'>
          <Thead>
            <Tr>
              <Th>{header[0]}</Th>
              <Th isNumeric>{header[1]}</Th>
              <Th isNumeric>{header[2]}</Th>
              <Th isNumeric>{header[3]}</Th>
              <Th isNumeric>{header[4]}</Th>
              <Th>{header[5]}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => (
              <Tr
                key={row.who.address}
                _hover={{
                  bg: "rgb(255 255 255 / 5%)",
                }}
                sx={{
                  "& > td": {
                    border: 'none'
                  }
                }}
              >
                <Td>
                  <div
                    style={{
                      width: 150,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Link
                      href={`https://etherscan.io/address//${row.who.address}`}
                      target="_blank"
                    >
                      <Annotation who={row.who} />
                    </Link>
                  </div>
                </Td>
                <Td isNumeric>{formatNumber(row.amount)}</Td>
                <Td isNumeric>{formatNumber(row.count, 0)}</Td>
                <Td isNumeric>{formatNumber(row.max_amount)}</Td>
                <Td isNumeric>{formatNumber(row.counterparties, 0)}</Td>
                <Td>{formatDistanceToNow(new Date(row.latest_date)).replace('about', '')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

export default function CardDistribution() {
  let { senders, receivers } = useAtomValue(tokenState);

  return (
    <>
      <Distribution
        data={senders}
        title="Distribution of Senders"
        header={[
          "Sender",
          "Amount",
          "Transfers count",
          "Max transfer amount",
          "Unique receivers",
          "Last transfer",
        ]}
      />

      <Distribution
        data={receivers}
        title="Distribution of Receivers"
        header={[
          "Receiver",
          "Amount",
          "Transfers count",
          "Max transfer amount",
          "Unique senders",
          "Last transfer",
        ]}
      />
    </>
  );
}
