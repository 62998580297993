import ky from "ky";

const zapper = ky.extend({
  prefixUrl: "https://api.zapper.fi/v1/",
  retry: 3,
});

const API_KEY = "api_key=96e0cc51-a62e-42ca-acee-910ea7d2a241";

export const fetchProtocolBalances = (address, protocol, network) =>
  zapper(
    `protocols/${protocol}/balances?addresses[]=${address}&network=${network}&${API_KEY}`,
  ).json();

export const fetchProtocols = (address) =>
  zapper(`protocols/balances/supported?addresses[]=${address}&${API_KEY}`)
    .json();

export const fetchStakedBalances = (address, type, network) =>
  zapper(
    `staked-balance/${type}?addresses[]=${address}&network=${network}&${API_KEY}`,
  ).json();

export const fetchPoolStats = (type, network) =>
  zapper(
    `pool-stats/${type}?network=${network}&${API_KEY}`,
  ).json();

export const fetchSupportedPoolStats = () =>
  zapper(
    `pool-stats/supported?${API_KEY}`,
  ).json();

export const fetchVaultStats = (type, network) =>
  zapper(
    `vault-stats/${type}?network=${network}&${API_KEY}`,
  ).json();

export const fetchSupportedVaultStats = () =>
  zapper(
    `vault-stats/supported?${API_KEY}`,
  ).json();

export const fetchFarms = (type, network) =>
  zapper(
    `farms/${type}?network=${network}&${API_KEY}`,
  ).json();
