import React from "react";
import { Box } from "@chakra-ui/react";
import { BarLineChart } from "./Chart";
import { tokenState } from "./store";
import { useAtomValue } from "jotai/utils";

export default function ChartTransfers() {
  let { transfers } = useAtomValue(tokenState);
  if (!transfers) return null;

  return (
    <Box rounded="md" bg="rgb(255 255 255 / 5%)">
      <BarLineChart
        data={transfers}
        title="Token Transfers by Month"
        barName="Amount"
        lineName="Count"
      />
    </Box>
  );
}
