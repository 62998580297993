import {
  getTokenHolders,
  getTokenInfo,
  queryAmountUSDofTXs,
  queryTokenDexTradesByMonth,
  queryTokenDistribution,
  queryTokenStats,
  queryTokenTransfersByMonth,
  queryTopHoldersTXs,
} from "../../APIs";

export async function fetchTokenProfile(tokenAddress, onCallback) {
  let result = JSON.parse(localStorage.getItem("Token" + tokenAddress) || "{}");
  if (
    result.lastUpdate &&
    Date.now() - result.lastUpdate < 8 * 60 * 60 * 1000 // 8 hours
  ) {
    return onCallback(result, 100);
  }

  let info = await getTokenInfo(tokenAddress);

  onCallback(
    {
      info,
    },
    20,
  );

  let {
    data: {
      ethereum: { stats },
    },
  } = await queryTokenStats(tokenAddress);
  stats = {
    ...stats[0],
    holdersCount: info.holdersCount,
  };

  onCallback(
    {
      stats,
    },
    40,
  );

  let {
    data: {
      ethereum: { transfers, flow },
    },
  } = await queryTokenTransfersByMonth(tokenAddress);

  transfers = transfers.map((t) => ({
    date: t.time.date,
    value1: t.amount,
    value2: t.count,
  }));

  flow = flow.map((t) => ({
    date: t.time.date,
    value1: t.senders,
    value2: t.receivers,
  }));

  onCallback(
    {
      transfers, flow
    },
    60,
  );

  let {
    data: {
      ethereum: { dexTrades },
    },
  } = await queryTokenDexTradesByMonth(tokenAddress);

  dexTrades = dexTrades.map((t) => ({
    date: t.time.date,
    value1: t.amount,
    value2: t.count,
  }));

  onCallback(
    {
      dexTrades,
    },
    70,
  );

  let {
    data: {
      ethereum: { senders, receivers },
    },
  } = await queryTokenDistribution(tokenAddress, 15);

  onCallback(
    {
      senders,
      receivers,
    },
    80,
  );

  let { holders: topHolders } = await getTokenHolders(tokenAddress, 20);
  let {
    data: {
      ethereum: { ins, outs },
    },
  } = await queryTopHoldersTXs(
    tokenAddress,
    topHolders.map((h) => h.address),
  );
  let topHoldersTXs = topHolders.map((h) => ({
    ...h,
    balance: h.balance / 10 ** Number(info.decimals),
    value: (h.balance / 10 ** Number(info.decimals)) * info.price.rate,
    ins: ins.find((t) => t.receiver.address === h.address),
    outs: outs.find((t) => t.sender.address === h.address),
  }));

  let {
    data: {
      ethereum: { transfersInUSD },
    },
  } = await queryAmountUSDofTXs(
    tokenAddress,
    topHoldersTXs
      .flatMap((h) => [h.ins.first_tx, h.outs?.last_tx])
      .filter((v) => !!v),
  );

  transfersInUSD.forEach((t) => {
    let h = topHoldersTXs.find(
      (h) =>
        t.transaction.hash === h.ins.first_tx ||
        t.transaction.hash === h.outs?.last_tx,
    );
    if (t.transaction.hash === h.ins.first_tx) {
      h.ins.firstInUSD = t.amountUSD / t.amount;
    } else if (t.transaction.hash === h.outs?.last_tx) {
      h.outs.lastOutUSD = t.amountUSD / t.amount;
    }
  });

  onCallback(
    {
      topHoldersTXs,
    },
    100,
  );

  localStorage.setItem(
    "Token" + tokenAddress,
    JSON.stringify({
      info,
      stats,
      flow,
      transfers,
      dexTrades,
      senders,
      receivers,
      topHoldersTXs,
      lastUpdate: Date.now(),
    }),
  );
}
