import React from "react";
import {
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Avatar,
  Text,
  Button,
  Link,
  Box,
  HStack,
  Switch,
  Flex,
} from "@chakra-ui/react";
import { formatUnits } from "@ethersproject/units";
import { useAtomValue } from "jotai/utils";
import { FiZap } from "react-icons/fi";
import { useBoolean } from "react-use";
import { formatNumber, shortenAddress, STABLES } from "../../utils";
import { aBalances } from "./store";

function ButtonFetchTokenProfile({ tokenAddress }) {

  return (
    <Button
      size="xs"
    >
      <FiZap />
    </Button>
  );
}

export default function Wallet() {
  let [shouldHideStable, toggleHideStable] = useBoolean();
  let [shouldHideSmall, toggleHideSmall] = useBoolean();
  let balances = useAtomValue(aBalances);
  if (!balances?.length) return null;

  if (shouldHideStable) {
    balances = balances.filter(
      (b) => !STABLES.includes(b.contract_ticker_symbol)
    );
  }

  if (shouldHideSmall) {
    balances = balances.filter((b) => b.quote && b.quote >= 1);
  }

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" color="gray.200">
          Wallet
        </Heading>
        <HStack spacing={4}>
          <Text>
            Hide small balances? <Switch ml={2} onChange={toggleHideSmall} />
          </Text>
          <Text>
            Hide Stables? <Switch ml={2} onChange={toggleHideStable} />
          </Text>
        </HStack>
      </Flex>
      <Table mt={4} borderRadius="lg" className='with-glass'>
        <Thead bg="rgb(0 0 0 / 10%)">
          <Tr>
            <Th>Token</Th>
            <Th>Contract Address</Th>
            <Th isNumeric>Balance</Th>
            <Th isNumeric>Value ($)</Th>
            <Th isNumeric>Rate ($)</Th>
            <Th textAlign="right">TXs In</Th>
            <Th textAlign="left">TXs Out</Th>
          </Tr>
        </Thead>
        <Tbody fontSize="sm">
          {balances.map((row) => (
            <Tr key={row.contract_name}>
              <Td display="flex" alignItems="center">
                <Avatar name={row.contract_name} src={row.logo_url} />
                <Box ml={4}></Box>
                <Text as="span" mr={2}>
                  {row.contract_name} ({row.contract_ticker_symbol})
                </Text>
                {!STABLES.includes(row.contract_ticker_symbol) && (
                  <ButtonFetchTokenProfile tokenAddress={row.contract_address} />
                )}
              </Td>

              <Td>
                {row.contract_ticker_symbol !== "ETH" && (
                  <Link
                    color="blue.300"
                    href={`https://etherscan.io/address/${row.contract_address}`}
                    target="_blank"
                  >
                    {shortenAddress(row.contract_address)}
                  </Link>
                )}
              </Td>
              <Td isNumeric>
                {formatNumber(formatUnits(row.balance, row.contract_decimals))}
              </Td>
              <Td isNumeric>{formatNumber(row.quote)}</Td>
              <Td isNumeric>{formatNumber(row.quote_rate, 4)}</Td>
              <Td>
                {row.tx && (
                  <Box textAlign="right">
                    <Text>{row.tx.count_in}</Text>
                    <Text>{formatNumber(row.tx.sum_in)}</Text>
                  </Box>
                )}
              </Td>
              <Td>
                {row.tx && (
                  <Box>
                    <Text>{row.tx.count_out}</Text>
                    <Text>{formatNumber(row.tx.sum_out)}</Text>
                  </Box>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
