import { atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { fetchWallet } from "./api";

export const walletState = atom(null);

export const aZapper = selectAtom(walletState, (wallet) => wallet?.zapper);

export const aBalances = selectAtom(walletState, (wallet) => wallet?.balances);

export const aMonthlyTXs = selectAtom(
  walletState,
  (wallet) => wallet?.monthlyTXs
);

export const walletFetchingState = atom(
  {
    isLoading: false,
    hasError: false,
    progress: 0,
  },
  (get, set, walletAddress) => {
    const fetchData = async () => {
      set(walletFetchingState, {
        isLoading: true,
        hasError: false,
        progress: 0,
      });

      try {
        await fetchWallet(walletAddress, (data, progress) => {
          set(walletState, {
            ...get(walletState),
            ...data
          });
          set(walletFetchingState, {
            isLoading: progress < 100,
            hasError: false,
            progress,
          });
        });
      } catch (error) {
        console.error(error);

        set(walletFetchingState, {
          isLoading: false,
          hasError: true,
          progress: get(walletFetchingState).progress,
        });

        alert(`Error!\n${error.message}\nPlease try again!`)
      }
    };

    fetchData();
  }
);
