import React from "react";
import {
  Box,
  HStack,
  SimpleGrid,
  Heading 
} from "@chakra-ui/react";
import CardInfo from "../features/TokenAuditor/CardInfo";
import CardStats from "../features/TokenAuditor/CardStats";
import CardDistribution from "../features/TokenAuditor/CardDistribution";
import ChartTransfers from "../features/TokenAuditor/ChartTransfers";
import ChartDexTrades from "../features/TokenAuditor/ChartDexTrades";
import ChartFlow from "../features/TokenAuditor/ChartFlow";
import TopHolders from "../features/TokenAuditor/TopHolders";
import ProgressBar from "../features/TokenAuditor/ProgressBar";
import InputAddress from "../features/TokenAuditor/InputAddress";

export default function TokenAuditor() {
  return (
    <Box minH="100vh" p={4}>
      <HStack>
        <Heading w="50%" size="lg">
          Token Auditor
        </Heading>

        <Box w="50%">
          <InputAddress defaultValue="0xf94b5c5651c888d928439ab6514b93944eee6f48" />
        </Box>
      </HStack>

      <ProgressBar />

      <SimpleGrid mt={8} columns={2} spacing={4}>
        <CardInfo />
        <CardStats />
        <ChartTransfers />
        <ChartDexTrades />
        <CardDistribution />
        <ChartFlow />
      </SimpleGrid>
      <Box mt={4}>
        <TopHolders />
      </Box>
    </Box>
  );
}
