import React, { useEffect, useRef } from "react";
import highcharts from "highcharts";
import setDarkTheme from "highcharts/themes/dark-unica";

setDarkTheme(highcharts);

export const getHighchartsOptions = highcharts.getOptions

export default function Highcharts({ options }) {
  let containerRef = useRef();

  useEffect(() => {
    highcharts.chart(containerRef.current, options).reflow();
  }, [options]);

  return (
    <figure>
      <div ref={containerRef}></div>
    </figure>
  );
}
