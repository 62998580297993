import { formatUnits } from "@ethersproject/units";
import {
  differenceInDays,
  format as formatDate,
  formatDistance,
} from "date-fns";
import { formatNumber } from "../../utils";

export const findMax = (series) => {
  let max = series[0];
  for (let i = 1, end = series.length; i < end; i++) {
    if (max.value < series[i].value) max = series[i];
  }

  return max;
};

export const formatSnapshot = (mapBy, transfers) => {
  if (!mapBy?.length || !transfers?.length) return [];

  return mapBy.map((row) => {
    let snapshot = transfers.find((t) =>
      row.address
        ? row.address === t.address
        : row.contract_address === t.contractAddress
    )?.snapshot;
    if (!snapshot) return [];

    let duration = differenceInDays(
      snapshot.lastOut?.block_signed_at
        ? new Date(snapshot.lastOut.block_signed_at)
        : Date.now(),
      new Date(snapshot.firstIn.block_signed_at),
    );

    let pct = snapshot.lastOut?.quote_rate &&
      snapshot.firstIn.quote_rate &&
      (snapshot.lastOut?.quote_rate / snapshot.firstIn.quote_rate) * 100;

    let timeToReachHighest = formatDistance(
      new Date(snapshot.firstIn.block_signed_at),
      new Date(snapshot.highest.time),
    );

    return [
      row.address
        ? `${row.address}`
        : `${row.contract_name} (${row.contract_ticker_symbol})`,
      formatNumber(row.quote),
      formatNumber(formatUnits(row.balance, row.contract_decimals)),
      formatDistance(new Date(snapshot.firstIn.block_signed_at), Date.now()),
      formatNumber(snapshot.highest.value),
      snapshot.txsCount < 2 ? "" : timeToReachHighest === "less than a minute"
        ? "First bought"
        : timeToReachHighest,
      formatNumber(snapshot.txs.gross),
      snapshot.txs.ins,
      snapshot.txs.outs,
      snapshot.txsCount,
      formatNumber(snapshot.txsCount / (duration || 1)),
      formatDistance(
        new Date(snapshot.firstIn.block_signed_at),
        snapshot.lastOut?.block_signed_at
          ? new Date(snapshot.lastOut.block_signed_at)
          : Date.now(),
      ),
      snapshot.avgHoldingDays,
      formatDate(new Date(snapshot.firstIn.block_signed_at), "dd/MM/yyyy"),
      snapshot.lastOut?.block_signed_at &&
      formatDate(new Date(snapshot.lastOut.block_signed_at), "dd/MM/yyyy"),
      snapshot.firstIn.quote_rate?.toFixed(4) || "",
      snapshot.lastOut?.quote_rate?.toFixed(4) || "",
      pct?.toFixed(2) || "",
    ];
  });
};
