import React from "react";
import { Box, Heading, HStack } from "@chakra-ui/react";
import InputWalletAddress from "../features/WalletAuditor/InputWalletAddress";
import ProgressBar from "../features/WalletAuditor/ProgressBar";
import Wallet from "../features/WalletAuditor/Wallet";
import ChartMonthlyTXs from "../features/WalletAuditor/ChartMonthlyTXs";
import WalletProfile from "../features/WalletAuditor/WalletProfile";
import Zapper from "../features/WalletAuditor/Zapper";

function App() {
  return (
    <Box minH="100vh" p={4}>
      <HStack>
        <Heading w="50%" size="lg">
          Wallet Auditor
        </Heading>

        <Box w="50%">
          <InputWalletAddress
            defaultValue="0xad8627895a7eA6b9fB8fE0b219D4EDA3A6cE45F6"
          />
        </Box>
      </HStack>

      <ProgressBar />

      {
        // <Box mt={8}>
        // <Zapper />
        // </Box>
      }

      <Box mt={8}>
        <Wallet />
      </Box>

      <Box mt={8}>
        <ChartMonthlyTXs />
      </Box>

      <Box mt={8}>
        <WalletProfile />
      </Box>
    </Box>
  );
}

export default App;
