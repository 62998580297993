import { atom } from "jotai";
import { fetchTokenProfile } from "./api";

export const tokenState = atom({
  info: null,
  stats: null,
  flow: null,
  transfers: null,
  dexTrades: null,
  senders: null,
  receivers: null,
  topHoldersTXs: null,
});

export const tokenFetchingState = atom(
  {
    isLoading: false,
    hasError: false,
    progress: 0,
  },
  (get, set, tokenAddress) => {
    if (!tokenAddress) {
      set(tokenState, null);
      return;
    }

    const fetchData = async () => {
      set(tokenFetchingState, {
        isLoading: true,
        hasError: false,
        progress: 0,
      });

      try {
        await fetchTokenProfile(tokenAddress, (data, progress) => {
          set(tokenState, {
            ...get(tokenState),
            ...data,
          });
          set(tokenFetchingState, {
            isLoading: progress < 100,
            hasError: false,
            progress,
          });
        });
      } catch (error) {
        console.error(error);

        set(tokenFetchingState, {
          isLoading: false,
          hasError: true,
          progress: get(tokenFetchingState).progress,
        });

        alert(`Error!\n${error.message}\nPlease try again!`);
      }
    };

    fetchData();
  },
);
