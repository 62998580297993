import currency from "currency.js";

export const STABLES = ["DAI", "USDC", "USDT", "GUSD", "ETH"];

export const shortenAddress = (address) =>
  address.substring(0, 6) + "..." + address.substring(38);

export const formatNumber = (number, digits = 2) => {
  if (isNaN(Number(number))) return "";

  return currency(number, { precision: digits }).format({ symbol: "" });
};

export const downloadFile = (name, content) => {
  let tempElA = document.createElement("a");
  tempElA.setAttribute("download", name);
  tempElA.setAttribute(
    "href",
    "data:text/csv;charset=utf-8, " + encodeURIComponent(content)
  );

  document.body.appendChild(tempElA);
  tempElA.click();
  document.body.removeChild(tempElA);
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getPriceColor = (price, base = 100) =>
  price > base ? "green.400" : price < base ? "red.400" : "";
