import ky from "ky";

const ethplorer = ky.extend({
  prefixUrl: "https://api.ethplorer.io/",
});

const API_KEY = "apiKey=EK-kJUnK-QV99Gqd-C1yjo";

export const getTokenInfo = (tokenAddress) =>
  ethplorer.get(`getTokenInfo/${tokenAddress}?${API_KEY}`).json();

export const getTokenHolders = (tokenAddress, limit = 10) =>
  ethplorer
    .get(`getTopTokenHolders/${tokenAddress}?${API_KEY}&limit=${limit}`)
    .json();
