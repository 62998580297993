import React from "react";
import { Box, HStack } from "@chakra-ui/react";
import { StackBarChart } from "./Chart";
import { useAtomValue } from "jotai/utils";
import { aMonthlyTXs } from "./store";

export default function ChartMonthlyTXs() {
  let monthlyTXs = useAtomValue(aMonthlyTXs);
  if (!monthlyTXs) return null;

  return (
    <HStack spacing={6} justify='center'>
      <Box>
        <StackBarChart
          title="Monthly transfers in Tokens"
          data={monthlyTXs.token}
          name1="Receive"
          name2="Send"
        />
      </Box>
      <Box>
        <StackBarChart
          title="Monthly transfers in ETH"
          data={monthlyTXs.eth}
          name1="Receive"
          name2="Send"
        />
      </Box>
    </HStack>
  );
}
