import React from "react";
import { Box } from "@chakra-ui/react";
import { BarLineChart } from "./Chart";
import { useAtomValue } from "jotai/utils";
import { tokenState } from "./store";

export default function ChartDexTrades() {
  let { dexTrades } = useAtomValue(tokenState);
  if (!dexTrades) return null;

  return (
    <Box rounded="md" bg="rgb(255 255 255 / 5%)">
      <BarLineChart
        data={dexTrades}
        title="Dex Trades by Month"
        barName="Amount"
        lineName="Count"
      />
    </Box>
  );
}
