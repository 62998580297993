import React from 'react'
import {
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Avatar,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai/utils";
import { walletState } from "./store";
import { formatSnapshot } from "./utils";
import { getPriceColor } from '../../utils';

export default function Profile() {
  let wallet = useAtomValue(walletState);
  if (!wallet) return null;
  let { balances, transfers } = wallet;
  let snapshots = formatSnapshot(balances, transfers);
  if (!snapshots.length) return null;

  return (
    <>
      <Heading size="md" color="gray.200">
        Smart Profile™
      </Heading>

      <Box w="100%" overflow="hidden">
        <Box mt={4} w="100%" h="calc(100vh - 20px)" overflow="auto">
          <Table borderRadius="lg" className='with-glass'>
            <Thead bg="rgb(0 0 0 / 10%)">
              <Tr>
                <Th>Token</Th>
                <Th isNumeric>Balance</Th>
                <Th isNumeric>Wallet Age</Th>
                <Th isNumeric>Highest</Th>
                <Th isNumeric>Gross TXs ($)</Th>
                <Th>TXs</Th>
                <Th isNumeric>TXs Count</Th>
                <Th isNumeric>Avg. TXs Daily</Th>
                <Th isNumeric>Duration</Th>
                <Th isNumeric>Avg. Holding Days</Th>
                <Th>First In</Th>
                <Th>Last Out</Th>
                <Th>First In ($)</Th>
                <Th>Last Out ($)</Th>
                <Th isNumeric>%</Th>
              </Tr>
            </Thead>
            <Tbody fontSize="xs">
              {snapshots.map((row, index) => {
                if (!row.length) return null;

                return (
                  <Tr key={row[0]} _hover={{ bg: "gray.600" }}>
                    <Td display="flex" alignItems="center">
                      <Avatar name={row[0]} src={balances[index].logo_url} />
                      <Text ml={4}>{row[0]}</Text>
                    </Td>
                    <Td isNumeric>
                      <Text fontSize="sm">{"$" + row[1]}</Text>
                      <Text>{row[2]}</Text>
                    </Td>
                    <Td isNumeric>{row[3]}</Td>
                    <Td isNumeric>
                      <Text>{row[4]}</Text>
                      <Text>{row[5]}</Text>
                    </Td>
                    <Td isNumeric>{"$" + row[6]}</Td>
                    <Td display="flex">
                      <Text py={1} px={2} bg="green.500" borderRadius="sm">
                        {row[7]}
                      </Text>
                      <Text ml={2} py={1} px={2} bg="red.500" borderRadius="sm">
                        {row[8]}
                      </Text>
                    </Td>
                    <Td isNumeric>{row[9]}</Td>
                    <Td isNumeric>{row[10]}</Td>
                    <Td isNumeric>{row[11]}</Td>
                    <Td isNumeric>{row[12]}</Td>
                    <Td isNumeric>{row[13]}</Td>
                    <Td isNumeric>{row[14]}</Td>
                    <Td isNumeric>{row[15]}</Td>
                    <Td isNumeric>{row[16]}</Td>
                    <Td isNumeric color={getPriceColor(row[17], 100)}>
                      {row[17]}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </>
  );
}
