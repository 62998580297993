import React from "react";
import { Icon } from "@chakra-ui/react";
import { FiCpu, FiRefreshCw, FiShare2, FiUser } from "react-icons/fi";
import { shortenAddress } from "../../utils";

export const Annotation = ({ who }) => {
  let prefix = <Icon as={FiUser} />;

  if (who.smartContract.contractType) {
    if (who.smartContract.contractType === "DEX") {
      prefix = <Icon as={FiRefreshCw} />;
    } else if (who.smartContract.contractType === "Multisig") {
      prefix = <Icon as={FiShare2} />;
    } else {
      prefix = <Icon as={FiCpu} />;
    }
  }

  if (who.annotation) {
    return <span>{prefix} {who.annotation.split(",")[0]}</span>;
  }

  return <span>{prefix} {shortenAddress(who.address)}</span>;
};
