import {
  fetchBalances,
  fetchTokenPrice,
  fetchTransfers,
  fetchTXPrices,
  getTokenHolders,
  getTokenInfo,
  queryAmountUSDofTXs,
  queryTokenDexTradesByMonth,
  queryTokenDistribution,
  queryTokenStats,
  queryTokensTXsOfAddress,
  queryTokenTransfersByMonth,
  queryTopHoldersTXs,
  queryTXs,
  queryTXsByMonth,
} from "../../APIs";
import { sleep, STABLES } from "../../utils";
import { findMax } from "./utils";
import { formatUnits } from "@ethersproject/units";
import { differenceInDays } from "date-fns";
import {
  fetchProtocolBalances,
  fetchProtocols,
  fetchStakedBalances,
} from "../../APIs/zapper";

export async function fetchWallet(address, onCallback) {
  let result = JSON.parse(localStorage.getItem(address) || "{}");

  if (
    result.lastUpdate &&
    Date.now() - result.lastUpdate < 8 * 60 * 60 * 1000 // 8 hours
  ) {
    return onCallback(result, 100);
  }

  let zapper = null //await fetchZapper(address);

  let {
    data: { items: balances },
  } = await fetchBalances(address);

  let {
    data: {
      ethereum: { TXs },
    },
  } = await queryTXs(address, balances.length);

  balances = balances.filter((row) => !!row.quote_rate);
  balances.sort((a, b) => b.quote - a.quote);

  balances.forEach((row) => {
    let tx = TXs.find((t) => t.currency.symbol === row.contract_ticker_symbol);
    row.tx = tx;
  });

  let progress = 5;
  onCallback({ zapper, balances }, progress);

  let {
    data: { token, eth },
  } = await queryTXsByMonth(address);

  let monthlyTXs = {
    token: token.transfers.map((t) => ({
      date: t.time.date,
      value1: t.ins,
      value2: t.outs,
    })),
    eth: eth.transfers.map((t) => ({
      date: t.time.date,
      value1: t.ins,
      value2: t.outs,
    })),
  };

  progress += 5;
  onCallback({ monthlyTXs }, progress);

  let tokenAddresses = balances
    .filter((b) => !STABLES.includes(b.contract_ticker_symbol))
    .map((b) => b.contract_address);

  let {
    data: {
      ethereum: { ins, outs },
    },
  } = await queryTokensTXsOfAddress(address, tokenAddresses);

  let tokensTXs = tokenAddresses.map((ta) => {
    return {
      balance: balances.find((b) => b.contract_address === ta),
      ins: ins.find((t) => t.currency.address === ta),
      outs: outs.find((t) => t.currency.address === ta),
    };
  });
  onCallback({ tokensTXs }, progress);

  let transfers = [];
  let unit = Math.ceil((1 / balances.length) * 90);
  for (let { contract_address, contract_ticker_symbol } of balances) {
    if (!STABLES.includes(contract_ticker_symbol)) {
      transfers.push(await fetchSnapshot(address, contract_address));
    }
    progress += unit;
    onCallback({ transfers }, progress);
  }

  localStorage.setItem(
    address,
    JSON.stringify({
      zapper,
      balances,
      monthlyTXs,
      tokensTXs,
      transfers,
      lastUpdate: Date.now(),
    }),
  );
}

export async function fetchTokenProfile(tokenAddress, onCallback) {
  let result = JSON.parse(localStorage.getItem("Token" + tokenAddress) || "{}");
  if (
    result.lastUpdate &&
    Date.now() - result.lastUpdate < 8 * 60 * 60 * 1000 // 8 hours
  ) {
    return onCallback(result, 100);
  }

  let info = await getTokenInfo(tokenAddress);

  onCallback(
    {
      info,
    },
    20,
  );

  let {
    data: {
      ethereum: { stats },
    },
  } = await queryTokenStats(tokenAddress);
  stats = {
    ...stats[0],
    holdersCount: info.holdersCount,
  };

  onCallback(
    {
      stats,
    },
    40,
  );

  let {
    data: {
      ethereum: { transfers },
    },
  } = await queryTokenTransfersByMonth(tokenAddress);

  transfers = transfers.map((t) => ({
    date: t.time.date,
    value1: t.amount,
    value2: t.count,
  }));

  onCallback(
    {
      transfers,
    },
    60,
  );

  let {
    data: {
      ethereum: { dexTrades },
    },
  } = await queryTokenDexTradesByMonth(tokenAddress);

  dexTrades = dexTrades.map((t) => ({
    date: t.time.date,
    value1: t.amount,
    value2: t.count,
  }));

  onCallback(
    {
      dexTrades,
    },
    70,
  );

  let {
    data: {
      ethereum: { senders, receivers },
    },
  } = await queryTokenDistribution(tokenAddress);

  onCallback(
    {
      senders,
      receivers,
    },
    80,
  );

  let { holders: topHolders } = await getTokenHolders(tokenAddress, 20);
  let {
    data: {
      ethereum: { ins, outs },
    },
  } = await queryTopHoldersTXs(
    tokenAddress,
    topHolders.map((h) => h.address),
  );
  let topHoldersTXs = topHolders.map((h) => ({
    ...h,
    balance: h.balance / 10 ** Number(info.decimals),
    value: (h.balance / 10 ** Number(info.decimals)) * info.price.rate,
    ins: ins.find((t) => t.receiver.address === h.address),
    outs: outs.find((t) => t.sender.address === h.address),
  }));

  let {
    data: {
      ethereum: { transfersInUSD },
    },
  } = await queryAmountUSDofTXs(
    tokenAddress,
    topHoldersTXs
      .flatMap((h) => [h.ins.first_tx, h.outs?.last_tx])
      .filter((v) => !!v),
  );

  transfersInUSD.forEach((t) => {
    let h = topHoldersTXs.find(
      (h) =>
        t.transaction.hash === h.ins.first_tx ||
        t.transaction.hash === h.outs?.last_tx,
    );
    if (t.transaction.hash === h.ins.first_tx) {
      h.ins.firstInUSD = t.amountUSD / t.amount;
    } else if (t.transaction.hash === h.outs?.last_tx) {
      h.outs.lastOutUSD = t.amountUSD / t.amount;
    }
  });

  onCallback(
    {
      topHoldersTXs,
    },
    100,
  );

  localStorage.setItem(
    "Token" + tokenAddress,
    JSON.stringify({
      info,
      stats,
      transfers,
      dexTrades,
      senders,
      receivers,
      topHoldersTXs,
      lastUpdate: Date.now(),
    }),
  );
}

async function fetchZapper(address) {
  let assets = [];
  let networks = await fetchProtocols(address);
  for (let n of networks) {
    for (let p of n.protocols) {
      await sleep(100);
      let balances = await fetchProtocolBalances(
        address,
        p.protocol,
        n.network,
      );
      assets.push(
        ...balances[address.toLowerCase()].products.flatMap((item) =>
          item.assets.map((a) => ({ ...a, n: n.network, p: p.protocol }))
        ),
      );
    }
  }

  for (let n of ["ethereum", "polygon", "binance-smart-chain"]) {
    await sleep(200);
    let stackedBalances = await Promise.all(
      ["gauge", "masterchef", "geyser", "single-staking"].map((t) =>
        fetchStakedBalances(address, t, n)
      ),
    );
    assets.push(
      ...stackedBalances.flatMap(b => b[address.toLowerCase()].map((a) => ({ ...a, n }))),
    );
  }

  return assets;
}

async function fetchHolders(contractAddress, pageSize = 10) {
  let {
    data: { items: balances },
  } = await ky(
    `https://api.covalenthq.com/v1/1/tokens/${contractAddress}/token_holders/?page-size=${pageSize}&sort={"balance":-1}&key=${COVALENT_KEY}`,
  ).json();

  let { contract } = await fetchContractPrice(contractAddress);

  balances.forEach((w) => {
    w.quote = Number(formatUnits(w.balance, w.contract_decimals)) *
      contract.price;
  });

  let transfers = [];
  let unit = (1 / balances.length) * 100;
  let progress = 0;
  for (let { address } of balances) {
    transfers.push(await fetchSnapshot(address, contractAddress));

    progress += unit;
  }
}

function avgHoldingDays(series) {
  let mark = series[0].time;
  let sum = 0;
  let count = 0;

  let length = series.length;
  for (let i = 1; i < length; i++) {
    // advance mark to the next bought time
    if (series[i - 1].value === 0) {
      mark = series[i].time;
    }

    if (series[i].value === 0) {
      count += 1;
      sum += differenceInDays(new Date(series[i].time), new Date(mark));
    }
  }

  // till hold to now
  if (series[length - 1].value > 99) {
    count += 1;
    sum += differenceInDays(new Date(), new Date(mark));
  }

  return Math.floor(sum / (count || 1));
}

async function fetchContractPrice(contractAddress) {
  try {
    let {
      data: { prices },
    } = await fetchTokenPrice(contractAddress);
    return { contractAddress, contract: prices[0] };
  } catch (error) {
    console.log({ contract, error });
    alert("Error!\n" + error.message + "\nPlease try again!");
    return { contractAddress, contract: null };
  }
}

async function fetchSnapshot(address, contractAddress) {
  await sleep(250);

  let {
    data: { items },
  } = await fetchTransfers(address, contractAddress);

  let transfers = items.flatMap((tx) => tx.transfers);
  let txsCount = transfers.length;
  if (txsCount === 0) {
    return { address, contractAddress, transfers: items, snapshot: null };
  }

  let firstIn = transfers[transfers.length - 1];
  let lastOut = transfers.find((s) => s.transfer_type === "OUT");

  let txs = transfers.reduce(
    (acc, tx) => {
      acc.gross += tx.delta_quote;

      if (tx.transfer_type === "IN") {
        acc.ins += 1;
      } else {
        acc.outs += 1;
      }

      return acc;
    },
    { ins: 0, outs: 0, gross: 0 },
  );

  let series = [];
  let value = 0;
  transfers.reverse().forEach((tx) => {
    if (tx.transfer_type === "IN") {
      value += Number(formatUnits(tx.delta, tx.contract_decimals));
    } else {
      value -= Number(formatUnits(tx.delta, tx.contract_decimals));
    }

    series.push({
      value,
      time: tx.block_signed_at,
    });
  });

  return {
    address,
    contractAddress,
    transfers: items,
    snapshot: {
      firstIn,
      lastOut,
      txsCount,
      txs,
      highest: findMax(series),
      avgHoldingDays: avgHoldingDays(series),
    },
  };
}
