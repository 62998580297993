import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Input,
  Link,
  Progress,
} from "@chakra-ui/react";
import { proxy, useSnapshot } from "valtio";
import ky from "ky";
import { formatUnits } from "@ethersproject/units";
import {
  format as formatDate,
  formatDistance,
  differenceInDays,
} from "date-fns";
import { useEffectOnce } from "react-use";

const COVALENT_KEY = "ckey_4f43826d38e04f32a46fe01f841";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const shortenAddress = (address) =>
  address.substring(0, 6) + "..." + address.substring(36);

String.prototype.toFixed = function (digits) {
  return Number(this).toFixed(digits);
};

const localState = proxy({
  walletAddress: null,
  isLoading: false,
  progress: 0,
  balances: [],
  transfers: [],
  txs: null,
  holders: {},
  topHolders: null,
});

function findMax(series) {
  let max = series[0];
  for (let i = 1, end = series.length; i < end; i++) {
    if (max.value < series[i].value) max = series[i];
  }

  return max;
}

function avgHoldingDays(series) {
  let mark = series[0].time;
  let sum = 0;
  let count = 0;

  let length = series.length;
  for (let i = 1; i < length; i++) {
    // advance mark to the next bought time
    if (series[i - 1].value === 0) {
      mark = series[i].time;
    }

    if (series[i].value === 0) {
      count += 1;
      sum += differenceInDays(new Date(series[i].time), new Date(mark));
    }
  }

  // till hold to now
  if (series[length - 1].value > 99) {
    count += 1;
    sum += differenceInDays(new Date(), new Date(mark));
  }

  return Math.floor(sum / (count || 1));
}

async function fetchContractPrice(contractAddress) {
  try {
    let {
      data: { prices },
    } = await ky(
      `https://api.covalenthq.com/v1/pricing/historical_by_address/1/usd/${contractAddress}/?key=${COVALENT_KEY}`
    ).json();
    return { contractAddress, contract: prices[0] };
  } catch (error) {
    console.log({ contract, error });
    alert("Error!\n" + error.message + "\nPlease try again!");
    return { contractAddress, contract: null };
  }
}

async function fetchTransfers(address, contractAddress) {
  await sleep(250);

  try {
    let {
      data: { items },
    } = await ky(
      `https://api.covalenthq.com/v1/1/address/${address}/transfers_v2/?contract-address=${contractAddress}&page-size=${50}&key=${COVALENT_KEY}`
    ).json();

    let transfers = items.flatMap((tx) => tx.transfers);
    let txsCount = transfers.length;
    if (txsCount === 0) {
      return { address, contractAddress, transfers: items, snapshot: null };
    }

    let firstIn = transfers[transfers.length - 1];
    let lastOut = transfers.find((s) => s.transfer_type === "OUT");

    let txs = transfers.reduce(
      (acc, tx) => {
        acc.gross += tx.delta_quote;

        if (tx.transfer_type === "IN") {
          acc.ins += 1;
        } else {
          acc.outs += 1;
        }

        return acc;
      },
      { ins: 0, outs: 0, gross: 0 }
    );

    let series = [];
    let value = 0;
    transfers.reverse().forEach((tx) => {
      if (tx.transfer_type === "IN") {
        value += Number(formatUnits(tx.delta, tx.contract_decimals));
      } else {
        value -= Number(formatUnits(tx.delta, tx.contract_decimals));
      }

      series.push({
        value,
        time: tx.block_signed_at,
      });
    });

    return {
      address,
      contractAddress,
      transfers: items,
      snapshot: {
        firstIn,
        lastOut,
        txsCount,
        txs,
        highest: findMax(series),
        avgHoldingDays: avgHoldingDays(series),
      },
    };
  } catch (error) {
    console.log({ contract, error });
    alert("Error!\n" + error.message + "\nPlease try again!");
    return { address, contractAddress, transfers: [], snapshot: null };
  }
}

async function fetchTopHolders(contractAddress, pageSize = 10) {
  try {
    if (localState.holders[contractAddress]) {
      localState.topHolders = localState.holders[contractAddress];
      return;
    }

    let result = JSON.parse(localStorage.getItem(contractAddress) || "{}");

    if (result.balances && result.v11) {
      localState.holders[contractAddress] = result;
      localState.topHolders = result;
      return;
    }

    localState.isLoading = true;

    let {
      data: { items: balances },
    } = await ky(
      `https://api.covalenthq.com/v1/1/tokens/${contractAddress}/token_holders/?page-size=${pageSize}&sort={"balance":-1}&key=${COVALENT_KEY}`
    ).json();

    let { contract } = await fetchContractPrice(contractAddress);

    balances.forEach((w) => {
      w.quote =
        Number(formatUnits(w.balance, w.contract_decimals)) * contract.price;
    });

    localState.holders[contractAddress] = {
      balances,
      transfers: [],
    };

    setTimeout(async () => {
      localState.progress = 0;
      let unit = (1 / balances.length) * 100;
      for (let { address } of balances) {
        localState.holders[contractAddress].transfers.push(
          await fetchTransfers(address, contractAddress)
        );

        localState.progress += unit;
      }

      localStorage.setItem(
        contractAddress,
        JSON.stringify({
          balances,
          transfers: localState.holders[contractAddress].transfers,
          v11: true,
        })
      );

      localState.topHolders = localState.holders[contractAddress];
      localState.isLoading = false;
    }, 1000);
  } catch (error) {
    alert("Error!\n" + error.message + "\nPlease try again!");
    console.log({ contractAddress, error });
    localState.isLoading = false;
  }
}

let STABLES = ["DAI", "USDC", "USDT", "GUSD", "ETH"];

async function fetchWallet(address) {
  localState.isLoading = true;
  localState.walletAddress = address.toLowerCase();

  try {
    let result = JSON.parse(localStorage.getItem(address) || "{}");

    if (!result.balances || !result.v11) {
      let {
        data: { items: balances },
      } = await ky(
        `https://api.covalenthq.com/v1/1/address/${address}/balances_v2/?key=${COVALENT_KEY}`
      ).json();

      localState.balances = balances;

      setTimeout(async () => {
        localState.progress = 0;
        let unit = (1 / balances.length) * 100;
        for (let { contract_address, contract_ticker_symbol } of balances) {
          if (!STABLES.includes(contract_ticker_symbol)) {
            localState.transfers.push(
              await fetchTransfers(address, contract_address)
            );
          }
          localState.progress += unit;
        }

        localStorage.setItem(
          address,
          JSON.stringify({
            balances,
            transfers: localState.transfers,
            v11: true,
          })
        );

        localState.isLoading = false;
      }, 1000);
    } else {
      localState.balances = result.balances;
      localState.transfers = result.transfers;
      localState.isLoading = false;
    }
  } catch (error) {
    console.error(error);
    alert("Error!\n" + error.message + "\nPlease try again!");
    localState.isLoading = false;
  }
}

function SectionInput() {
  let { isLoading } = useSnapshot(localState);
  let [address, setAddress] = useState(
    "0xad8627895a7eA6b9fB8fE0b219D4EDA3A6cE45F6"
  );

  return (
    <HStack spacing={4}>
      <Input value={address} onChange={(e) => setAddress(e.target.value)} />
      <Button
        variant="brand"
        isLoading={isLoading}
        onClick={() => fetchWallet(address)}
      >
        Fetch data
      </Button>
    </HStack>
  );
}

function viewTransfer(contract) {
  localState.txs = localState.transfers.find(
    (row) => row.contract === contract
  );
}

function formatCurrency(number) {
  return Number(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

function TableBalances({ data }) {
  return (
    <>
      <Heading size="md" color="gray.200">
        Wallet
      </Heading>
      <Table mt={4} borderRadius="lg" bg="gray.700">
        <Thead bg="rgb(0 0 0 / 10%)">
          <Tr>
            <Th>Token</Th>
            <Th>Contract Address</Th>
            <Th isNumeric>Balance</Th>
            <Th isNumeric>Value ($)</Th>
            <Th isNumeric>Rate ($)</Th>
          </Tr>
        </Thead>
        <Tbody fontSize="sm">
          {data.map((row) => (
            <Tr key={row.contract_name}>
              <Td display="flex" alignItems="center">
                <Image src={row.logo_url} w={8} h={8} />
                <Text ml={4}>
                  {row.contract_name} ({row.contract_ticker_symbol})
                </Text>
              </Td>
              <Td>
                <Link
                  color="blue.300"
                  href={`https://etherscan.io/address/${row.contract_address}`}
                  target="_blank"
                >
                  {shortenAddress(row.contract_address)}
                </Link>
              </Td>
              <Td isNumeric>
                {formatCurrency(
                  formatUnits(row.balance, row.contract_decimals)
                )}
              </Td>
              <Td isNumeric>{formatCurrency(row.quote)}</Td>
              <Td isNumeric>{row.quote_rate?.toFixed(4)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

function ModalTopHolders({ title, data, walletAddress }) {
  let snapshots = formatSnapshot(
    data.wallet.map((w) => ({ ...w.content, address: w.address })),
    data.snapshot.map((s) => s.content)
  );

  return (
    <>
      <Heading size="md" color="gray.200">
        Top Holders for {title}
      </Heading>

      <Box mt={4} width="100%" overflow="hidden">
        <Box width="100%" overflow="auto">
          <Table bg="gray.700">
            <Thead>
              <Tr>
                <Th>Address</Th>
                <Th isNumeric>Balance</Th>
                <Th isNumeric>Wallet Age</Th>
                <Th isNumeric>Highest</Th>
                <Th isNumeric>Gross TXs ($)</Th>
                <Th>TXs</Th>
                <Th isNumeric>TXs Count</Th>
                <Th isNumeric>Avg. TXs Daily</Th>
                <Th isNumeric>Duration</Th>
                <Th isNumeric>Avg. Holding Days</Th>
                <Th>First In</Th>
                <Th>Last Out</Th>
                <Th>First In ($)</Th>
                <Th>Last Out ($)</Th>
                <Th isNumeric>%</Th>
              </Tr>
            </Thead>
            <Tbody fontSize="xs">
              {snapshots?.map((row) => {
                if (!row.length) return null;

                return (
                  <Tr
                    key={row[0]}
                    bg={row[0] === walletAddress ? "gray.500" : ""}
                    _hover={{ bg: "gray.600" }}
                  >
                    <Td display="flex" alignItems="center">
                      <Link
                        color="blue.300"
                        href={`https://etherscan.io/address/${row[0]}`}
                        target="_blank"
                      >
                        {shortenAddress(row[0])}
                      </Link>
                    </Td>
                    <Td isNumeric>
                      <Text fontSize="sm">{"$" + row[1]}</Text>
                      <Text>{row[2]}</Text>
                    </Td>
                    <Td isNumeric>{row[3]}</Td>
                    <Td isNumeric>
                      <Text>{row[4]}</Text>
                      <Text>{row[5]}</Text>
                    </Td>
                    <Td isNumeric>{"$" + row[6]}</Td>
                    <Td display="flex">
                      <Text py={1} px={2} bg="green.500" borderRadius="sm">
                        {row[7]}
                      </Text>
                      <Text ml={2} py={1} px={2} bg="red.500" borderRadius="sm">
                        {row[8]}
                      </Text>
                    </Td>
                    <Td isNumeric>{row[9]}</Td>
                    <Td isNumeric>{row[10]}</Td>
                    <Td isNumeric>{row[11]}</Td>
                    <Td isNumeric>{row[12]}</Td>
                    <Td isNumeric>{row[13]}</Td>
                    <Td isNumeric>{row[14]}</Td>
                    <Td isNumeric>{row[15]}</Td>
                    <Td isNumeric>{row[16]}</Td>
                    <Td
                      isNumeric
                      color={`${
                        row[17] > 100
                          ? "green.400"
                          : row[17] < 100
                          ? "red.400"
                          : ""
                      }`}
                    >
                      {row[17]}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </>
  );
}

const LABEL = {
  "0xeff2dbe03e67ee5e5a6b645cb61a1c0dcfd890d9": "Uniswap V2: STAK 4",
  "0x4b1a99467a284cc690e3237bc69105956816f762": "Bitmax 2",
  "0xbbcd9986304fe340870f4816c614ba0c7a53512e": "Uniswap V2: HYVE 67",
  "0x9339227db67f747114c929b26b81fe7974436b94": "Uniswap V2: YLD 16",
  "0xf94b5c5651c888d928439ab6514b93944eee6f48": "Bitmax 2",
  "0x986a2fca9eda0e06fbf7839b89bfc006ee2a23dd": "Bitmax 3",
};

function formatSnapshot(mapBy, snapshots) {
  if (mapBy[0].address) {
    mapBy.sort((a, b) => b.quote - a.quote);
  }

  return mapBy.map((row) => {
    let snapshot = snapshots.find((s) =>
      row.address
        ? row.address === s.firstIn.from_address ||
          row.address === s.firstIn.to_address
        : row.contract_address === s.firstIn.contract_address
    );

    if (!snapshot) return [];

    let duration = differenceInDays(
      snapshot.lastOut?.block_signed_at
        ? new Date(snapshot.lastOut.block_signed_at)
        : Date.now(),
      new Date(snapshot.firstIn.block_signed_at)
    );

    let pct =
      snapshot.lastOut?.quote_rate &&
      snapshot.firstIn.quote_rate &&
      (snapshot.lastOut?.quote_rate / snapshot.firstIn.quote_rate) * 100;

    let timeToReachHighest = formatDistance(
      new Date(snapshot.firstIn.block_signed_at),
      new Date(snapshot.highest.time)
    );

    return [
      row.address
        ? `${LABEL[row.address] || row.address}`
        : `${row.contract_name} (${row.contract_ticker_symbol})`,
      formatCurrency(row.quote),
      formatCurrency(formatUnits(row.balance, row.contract_decimals)),
      formatDistance(new Date(snapshot.firstIn.block_signed_at), Date.now()),
      formatCurrency(snapshot.highest.value),
      snapshot.txsCount < 2
        ? ""
        : timeToReachHighest === "less than a minute"
        ? "First bought"
        : timeToReachHighest,
      formatCurrency(snapshot.txs.gross),
      snapshot.txs.ins,
      snapshot.txs.outs,
      snapshot.txsCount,
      (snapshot.txsCount / (duration || 1)).toFixed(2),
      formatDistance(
        new Date(snapshot.firstIn.block_signed_at),
        snapshot.lastOut?.block_signed_at
          ? new Date(snapshot.lastOut.block_signed_at)
          : Date.now()
      ),
      snapshot.avgHoldingDays,
      formatDate(new Date(snapshot.firstIn.block_signed_at), "dd/MM/yyyy"),
      snapshot.lastOut?.block_signed_at &&
        formatDate(new Date(snapshot.lastOut.block_signed_at), "dd/MM/yyyy"),
      snapshot.firstIn.quote_rate?.toFixed(4) || "",
      snapshot.lastOut?.quote_rate?.toFixed(4) || "",
      pct?.toFixed(2) || "",
    ];
  });
}

function TableSnapshot({ data }) {
  let snapshots = formatSnapshot(data.wallet, data.snapshot);
  if (!snapshots.length) return null;

  return (
    <>
      <Heading size="md" color="gray.200">
        Smart Profile™{" "}
      </Heading>

      <Box mt={4} w="100%" h="100vh" overflow="auto">
        <Table borderRadius="lg" bg="gray.700">
          <Thead bg="rgb(0 0 0 / 10%)">
            <Tr>
              <Th>Token</Th>
              <Th isNumeric>Balance</Th>
              <Th isNumeric>Wallet Age</Th>
              <Th isNumeric>Highest</Th>
              <Th isNumeric>Gross TXs ($)</Th>
              <Th>TXs</Th>
              <Th isNumeric>TXs Count</Th>
              <Th isNumeric>Avg. TXs Daily</Th>
              <Th isNumeric>Duration</Th>
              <Th isNumeric>Avg. Holding Days</Th>
              <Th>First In</Th>
              <Th>Last Out</Th>
              <Th>First In ($)</Th>
              <Th>Last Out ($)</Th>
              <Th isNumeric>%</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="xs">
            {snapshots.map((row, index) => {
              if (!row.length) return null;

              return (
                <Tr key={row[0]} _hover={{ bg: "gray.600" }}>
                  <Td display="flex" alignItems="center">
                    <Image src={data.wallet[index].logo_url} w={8} h={8} />
                    <Text ml={4}>{row[0]}</Text>
                  </Td>
                  <Td isNumeric>
                    <Text fontSize="sm">{"$" + row[1]}</Text>
                    <Text>{row[2]}</Text>
                  </Td>
                  <Td isNumeric>{row[3]}</Td>
                  <Td isNumeric>
                    <Text>{row[4]}</Text>
                    <Text>{row[5]}</Text>
                  </Td>
                  <Td isNumeric>{"$" + row[6]}</Td>
                  <Td display="flex">
                    <Text py={1} px={2} bg="green.500" borderRadius="sm">
                      {row[7]}
                    </Text>
                    <Text ml={2} py={1} px={2} bg="red.500" borderRadius="sm">
                      {row[8]}
                    </Text>
                  </Td>
                  <Td isNumeric>{row[9]}</Td>
                  <Td isNumeric>{row[10]}</Td>
                  <Td isNumeric>{row[11]}</Td>
                  <Td isNumeric>{row[12]}</Td>
                  <Td isNumeric>{row[13]}</Td>
                  <Td isNumeric>{row[14]}</Td>
                  <Td isNumeric>{row[15]}</Td>
                  <Td isNumeric>{row[16]}</Td>
                  <Td
                    isNumeric
                    color={`${
                      row[17] > 100
                        ? "green.400"
                        : row[17] < 100
                        ? "red.400"
                        : ""
                    }`}
                  >
                    {row[17]}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

function ProgressBar() {
  let progress = Math.ceil(useSnapshot(localState).progress);

  if (progress >= 100 || progress === 0) return null;

  return (
    <Box mt={4} position="sticky" top={0}>
      <Progress isAnimated colorScheme="cyan" value={progress} />
    </Box>
  );
}

function App() {
  let [state, setState] = useState();
  useEffectOnce(() => {
    let fetchData = async () => {
      let { wallet, snapshot } = await ky(
        `https://wa-production.up.railway.app/wallet?address=0xad8627895a7eA6b9fB8fE0b219D4EDA3A6cE45F6`
      ).json();
      let jigstack = await ky(
        `https://wa-production.up.railway.app/top-holders?address=0x1f8a626883d7724dbd59ef51cbd4bf1cf2016d13`
      ).json();

      let shopx = await ky(
        `https://wa-production.up.railway.app/top-holders?address=0x7bef710a5759d197ec0bf621c3df802c2d60d848`
      ).json();

      let hyve = await ky(
        `https://wa-production.up.railway.app/top-holders?address=0xd794dd1cada4cf79c9eebaab8327a1b0507ef7d4`
      ).json();

      let yld = await ky(
        `https://wa-production.up.railway.app/top-holders?address=0xf94b5c5651c888d928439ab6514b93944eee6f48`
      ).json();

      setState({
        wallet: wallet.map((w) => w.content),
        snapshot: snapshot.map((w) => w.content),
        jigstack,
        shopx,
        hyve,
        yld,
      });
    };

    fetchData();
  });

  if (!state) return null;

  return (
    <Box minH="100vh" p={4}>
      <Heading size="lg">Wallet Auditor</Heading>

      <Box mt={6}>
        <TableBalances data={state.wallet} />
      </Box>

      <Box mt={6}>
        <TableSnapshot data={state} />
      </Box>

      <Box mt={6}>
        <ModalTopHolders
          title="Yield (YLD)"
          data={state.yld}
          walletAddress="0xad8627895a7eA6b9fB8fE0b219D4EDA3A6cE45F6"
        />
      </Box>

      <Box mt={6}>
        <ModalTopHolders
          title="Jigstack (STAK)"
          data={state.jigstack}
          walletAddress="0xad8627895a7eA6b9fB8fE0b219D4EDA3A6cE45F6"
        />
      </Box>

      <Box mt={6}>
        <ModalTopHolders
          title="SPLYT SHOPX"
          data={state.shopx}
          walletAddress="0xad8627895a7eA6b9fB8fE0b219D4EDA3A6cE45F6"
        />
      </Box>

      <Box mt={6}>
        <ModalTopHolders
          title="HYVE"
          data={state.hyve}
          walletAddress="0xad8627895a7eA6b9fB8fE0b219D4EDA3A6cE45F6"
        />
      </Box>
    </Box>
  );
}

export default App;
