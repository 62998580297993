import ky from "ky";

const bitQuery = ky.extend({
  prefixUrl: "https://graphql.bitquery.io/",
  method: "post",
  headers: {
    "X-API-KEY": "BQYx5svQocsTqzXaK9QJ5AbqD92bVeVD",
  },
});

export const queryTXs = (address, limit = 10) =>
  bitQuery("", {
    json: {
      variables: {
        address,
        limit,
        offset: 0,
        network: "ethereum",
        from: null,
        till: null,
        dateFormat: "%Y-%m",
      },
      query:
        `query ($network: EthereumNetwork!, $address: String!, $from: ISO8601DateTime, $till: ISO8601DateTime, $limit: Int!, $offset: Int!) {
        ethereum(network: $network) {
          TXs: transfers(date: {since: $from, till: $till}, amount: {gt: 0}, options: {limit: $limit, offset: $offset, desc: ["count_in", "count_out"], asc: "currency.symbol"}) {
            sum_in: amount(calculate: sum, receiver: {is: $address})
            sum_out: amount(calculate: sum, sender: {is: $address})
            count_in: count(receiver: {is: $address})
            count_out: count(sender: {is: $address})
            currency {
              address
              symbol
              tokenType
            }
          }
        }
      }
    `,
    },
  }).json();

export const queryTXsByMonth = (address, limit = 12) =>
  bitQuery("", {
    json: {
      variables: {
        address,
        limit,
        network: "ethereum",
        from: null,
        till: null,
        dateFormat: "%Y-%m",
      },
      query:
        `query ($network: EthereumNetwork!, $dateFormat: String!, $address: String!, $limit: Int!, $from: ISO8601DateTime, $till: ISO8601DateTime) {
        token: ethereum(network: $network) {
          transfers(
            date: {since: $from, till: $till}
            amount: {gt: 0}
            options: {asc: "time.date", limit: $limit}
            currency: {not: "ETH"}
          ) {
            time: date {
              date(format: $dateFormat)
            }
            ins: count(receiver: {is: $address})
            outs: count(sender: {is: $address})
          }
        }
        eth: ethereum(network: $network) {
          transfers(
            date: {since: $from, till: $till}
            amount: {gt: 0}
            options: {asc: "time.date", limit: 10}
            currency: {is: "ETH"}
          ) {
            time: date {
              date(format: $dateFormat)
            }
            ins: count(receiver: {is: $address})
            outs: count(sender: {is: $address})
          }
        }
      }      
      `,
    },
  }).json();

export const queryTokenStats = (address) =>
  bitQuery("", {
    json: {
      variables: {
        token: address,
        network: "ethereum",
        from: null,
        till: null,
      },
      query:
        `query ($network: EthereumNetwork!, $token: String!, $from: ISO8601DateTime, $till: ISO8601DateTime) {
        ethereum(network: $network) {
          stats: transfers(currency: {is: $token}, amount: {gt: 0}, date: {since: $from, till: $till}) {
            median: amount(calculate: median)
            average: amount(calculate: average)
            amount
            count
            age: count(uniq: dates)
            senders_count: count(uniq: senders)
            receivers_count: count(uniq: receivers)
            first: minimum(of: date)
            last: maximum(of: date)
          }
        }
      }      
      `,
    },
  }).json();

export const queryTokenTransfersByMonth = (address) =>
  bitQuery("", {
    json: {
      variables: {
        token: address,
        network: "ethereum",
        dateFormat: "%Y-%m",
        from: null,
        till: null,
      },
      query:
        `query ($network: EthereumNetwork!, $token: String!, $dateFormat: String!, $from: ISO8601DateTime, $till: ISO8601DateTime) {
        ethereum(network: $network) {
          transfers(currency: {is: $token}, height: {gt: 0}, amount: {gt: 0}, date: {since: $from, till: $till}) {
            time: date {
              date(format: $dateFormat)
            }
            count
            amount
          }

          flow: transfers(
            currency: {is: $token}
          ) {
            senders: count(uniq: senders)
            receivers: count(uniq: receivers)
            time: date {
              date(format: $dateFormat)
            }
          }
        }
      }
      `,
    },
  }).json();

export const queryTokenDexTradesByMonth = (address) =>
  bitQuery("", {
    json: {
      variables: {
        token: address,
        network: "ethereum",
        dateFormat: "%Y-%m",
        from: null,
        till: null,
      },
      query:
        `query ($network: EthereumNetwork!, $dateFormat: String!, $token: String!, $from: ISO8601DateTime, $till: ISO8601DateTime) {
        ethereum(network: $network) {
          dexTrades(
            options: {asc: "time.date"}
            date: {since: $from, till: $till}
            baseCurrency: {is: $token}
          ) {
            time: date {
              date(format: $dateFormat)
            }
            count
            amount: baseAmount
          }
        }
      }            
      `,
    },
  }).json();

export const queryTokenDistribution = (address, limit = 10) =>
  bitQuery("", {
    json: {
      variables: {
        token: address,
        limit,
        offset: 0,
        network: "ethereum",
        dateFormat: "%Y-%m",
        from: null,
        till: null,
      },
      query:
        `query ($network: EthereumNetwork!, $token: String!, $limit: Int, $offset: Int, $from: ISO8601DateTime, $till: ISO8601DateTime) {
        ethereum(network: $network) {
          senders: transfers(
            currency: {is: $token}
            date: {since: $from, till: $till}
            height: {gt: 0}
            amount: {gt: 0}
            options: {desc: "amount", limit: $limit, offset: $offset}
          ) {
            who: sender {
              address
              annotation
              smartContract {
                contractType
              }
            }
            amount
            count
            counterparties: count(uniq: receivers)
            max_amount: maximum(of: amount, get: amount)
            latest_date: maximum(of: date)
          }
          
          receivers: transfers(
            currency: {is: $token}
            date: {since: $from, till: $till}
            height: {gt: 0}
            amount: {gt: 0}
            options: {desc: "amount", limit: $limit, offset: $offset}
          ) {
            who: receiver {
              address
              annotation
              smartContract {
                contractType
              }
            }
            amount
            count
            counterparties: count(uniq: senders)
            max_amount: maximum(of: amount, get: amount)
            latest_date: maximum(of: date)
          }
        }
      }   
    `,
    },
  }).json();

export const queryTopHoldersTXs = (tokenAddress, topHolders) =>
  bitQuery("", {
    json: {
      variables: {
        tokenAddress,
        topHolders,
        network: "ethereum",
      },
      query:
        `query ($network: EthereumNetwork!, $tokenAddress: String!, $topHolders: [String!]) {
        ethereum(network: $network) {
          ins: transfers(
            currency: {is: $tokenAddress}
            amount: {gt: 0}
            any: {receiver: {in: $topHolders}}
          ) {
            receiver {
              address
              annotation
              smartContract {
                contractType
              }
            }
            txs: count
            ins: count(uniq: senders)
            sum: amount(calculate: sum)
            max_amount: maximum(of: amount)
            max_date: maximum(of: amount, get: date)
            first_in: minimum(of: date)
            first_tx: minimum(of: date, get: tx_hash)
          }
          outs: transfers(
            currency: {is: $tokenAddress}
            amount: {gt: 0}
            any: {sender: {in: $topHolders}}
          ) {
            sender {
              address
            }
            txs: count
            outs: count(uniq: receivers)
            sum: amount(calculate: sum)
            max_amount: maximum(of: amount, get: amount)
            max_date: maximum(of: amount, get: date)
            last_out: maximum(of: date)
            last_tx: maximum(of: date, get: tx_hash)
          }
        }
      }
    `,
    },
  }).json();

export const queryTokensTXsOfAddress = (address, tokenAddresses) =>
  bitQuery("", {
    json: {
      variables: {
        address,
        tokenAddresses,
        network: "ethereum",
      },
      query:
        `query ($network: EthereumNetwork!, $tokenAddresses: [String!], $address: String!) {
        ethereum(network: $network) {
          ins: transfers(
            currency: {in: $tokenAddresses}
            amount: {gt: 0}
            any: {receiver: {is: $address}}
          ) {
            currency {
              address
            }
            txs: count
            ins: count(uniq: senders)
            sum: amount(calculate: sum)
            max_amount: maximum(of: amount)
            max_date: maximum(of: amount, get: date)
            first_in: minimum(of: date)
            first_tx: minimum(of: date, get: tx_hash)
          }
          outs: transfers(
            currency: {in: $tokenAddresses}
            amount: {gt: 0}
            any: {sender: {is: $address}}
          ) {
            currency {
              address
            }
            txs: count
            ins: count(uniq: senders)
            sum: amount(calculate: sum)
            max_amount: maximum(of: amount)
            max_date: maximum(of: amount, get: date)
            last_date: minimum(of: date)
            last_tx: minimum(of: date, get: tx_hash)
          }
        }
      }
    `,
    },
  }).json();

export const queryAmountUSDofTXs = (tokenAddress, txHashes) =>
  bitQuery("", {
    json: {
      variables: {
        tokenAddress,
        txHashes,
        network: "ethereum",
      },
      query:
        `query ($network: EthereumNetwork!, $tokenAddress: String!, $txHashes: [String!]) {
      ethereum(network: $network) {
        transfersInUSD: transfers(currency: {is: $tokenAddress} txHash: {in: $txHashes}) {
          transaction {
            hash
          }
          amount
          amountUSD: amount(in: USD)
          currency {
            symbol
          }
        }
      }
    }
  `,
    },
  }).json();

export const queryBitcoin = (wallets) =>
  bitQuery("", {
    json: {
      variables: {
        wallets,
        network: "bitcoin",
        "from": null,
        "till": null,
      },
      query:
        `query ($network: BitcoinNetwork!, $wallets: [String!], $from: ISO8601DateTime, $till: ISO8601DateTime) {
        bitcoin(network: $network) {
          inputs(date: {since: $from, till: $till}, inputAddress: {in: $wallets}) {
            inputAddress {
              address
            }
            count
            value
            min_date: minimum(of: date)
            max_date: maximum(of: date)
          }
          outputs(date: {since: $from, till: $till}, outputAddress: {in: $wallets}) {
            outputAddress {
              address
            }
            count
            value
            min_date: minimum(of: date)
            max_date: maximum(of: date)
          }
        }
      }      
`,
    },
  }).json();

export const queryEarlyHolders = (tokenAddress, since = null, till = null, limit = 10) =>
  bitQuery("", {
    json: {
      variables: {
        tokenAddress,
        limit,
        till,
        since,
        network: "ethereum",
      },
      query:
        `query ($network: EthereumNetwork!, $tokenAddress: String!, $since: ISO8601DateTime!, $till: ISO8601DateTime!, $limit: Int!) {
        ethereum(network: $network) {
          earlyHolders: transfers(
            currency: {is: $tokenAddress}
            amount: {gt: 0}
            date: {since: $since, till: $till}
            options: {limit: $limit, desc: "max_amount"}
          ) {
            receiver {
              address
              annotation
              smartContract {
                contractType
                protocolType
              }
            }
            date {
              date
            }
            txs: count
            ins: count(uniq: senders)
            sum: amount(calculate: sum)
            max_amount: maximum(of: amount)
            max_date: maximum(of: amount, get: date)
            first_in: minimum(of: date)
            first_tx: minimum(of: date, get: tx_hash)
          }
        }
      }
    `,
    },
  }).json();

  
export const queryHoldings = (tokenAddress, holders) =>
bitQuery("", {
  json: {
    variables: {
      tokenAddress,
      holders,
      network: "ethereum",
    },
    query:
      `query ($network: EthereumNetwork!, $tokenAddress: String!, $holders: [String!]) {
      ethereum(network: $network) {
        holdings: transfers(
          currency: { is: $tokenAddress }
          sender: { in: $holders }
          options: { desc: "last_date" }
        ) {
          sender {
            address
          }
          receivers: count(uniq: receivers)
          max_amount: maximum(of: amount)
          max_usd: amount(calculate: maximum, in: USD)
          last_date: maximum(of: amount, get: date)
          last_tx: maximum(of: date, get: tx_hash)
        }
      }
    }
  `,
  },
}).json();

export const queryBitcoinSingle = (address) =>
  bitQuery("", {
    json: {
      variables: {
        address,
        network: "bitcoin",
        "from": null,
        "till": null,
      },
      query:
        `query ($network: BitcoinNetwork!, $address: String!, $from: ISO8601DateTime, $till: ISO8601DateTime) {
      bitcoin(network: $network) {
        inputs(date: {since: $from, till: $till}, inputAddress: {is: $address}) {
          inputAddress {
            address
          }
          count
          value
          min_date: minimum(of: date)
          max_date: maximum(of: date)
        }
        outputs(date: {since: $from, till: $till}, outputAddress: {is: $address}) {
          outputAddress {
            address
          }
          count
          value
          min_date: minimum(of: date)
          max_date: maximum(of: date)
        }
      }
    }      
`,
    },
  }).json();
