import React from "react";
import { Provider as AtomProvider } from "jotai";
import { Router } from "@reach/router";
import { ChakraProvider, DarkMode } from "@chakra-ui/react";
import theme from "./theme";
import WalletAuditor from "./pages/WalletAuditor";
import TokenAuditor from "./pages/TokenAuditor";
import POC from "./pages/POC";
import Bitcoin from "./pages/Bitcoin";
import Farms from "./pages/Farms";
import Header from "./Header";

export default function App() {
  return (
    <AtomProvider>
      <ChakraProvider theme={theme}>
        <DarkMode>
          <Header />
          <Router>
            <WalletAuditor path="/wallet" />
            <TokenAuditor path="/token" />
            <POC path="/poc" />
            <Bitcoin path="/bitcoin" />
            <Farms path="/farms" />
          </Router>
        </DarkMode>
      </ChakraProvider>
    </AtomProvider>
  );
}
