import React from "react";
import { useAtomValue } from "jotai/utils";
import { Box, Progress } from "@chakra-ui/react";
import { walletFetchingState } from "./store";

export default function ProgressBar() {
  let { progress, hasError } = useAtomValue(walletFetchingState);
  if (progress >= 100 || progress === 0) return null;

  return (
    <Box mt={4} position="sticky" top={0}>
      <Progress
        isAnimated
        colorScheme={hasError ? "red" : "cyan"}
        value={progress}
      />
    </Box>
  );
}
