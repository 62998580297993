import { theme as baseTheme, extendTheme } from "@chakra-ui/react";

let theme = extendTheme({
  colors: {
    brand: baseTheme.colors.cyan,
  },
  styles: {
    global: {
      "body": {
        color: "gray.50",

        '--rgt-background-color': '#2D3748',
        '--rgt-shadow-color': 'rgb(0 0 0 / 0.25)',
        '--rgt-border-color': '#4A5568',
        '--rgt-button-color': '#00B5D8',
        '--rgt-color1': '#EDF2F7',
        '--rgt-color2': '#CBD5E0',
        '--rgt-color3': '#718096',
        '--rgt-color4': 'yellow',
        '--rgt-color5': '#f5f5f5',
        '--rgt-border': '1px solid var(--rgt-bor,er-color)'
      },
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: 'cyan.500'
      }
    },
    Input: {
      baseStyle: {
        field: {
          boxShadow: "inner",
        },
      },
    },
    Table: {
      sizes: {
        xs: {
          th: {
            px: "4",
            py: "1",
            lineHeight: "4",
            fontSize: "xs",
          },
          td: {
            px: "4",
            py: "2",
            fontSize: "xs",
            lineHeight: "4",
          },
          caption: {
            px: "4",
            py: "2",
            fontSize: "xs",
          },
        }
      }
    },
    Button: {
      baseStyle: {
        shadow: "sm",
        _hover: {
          shadow: "lg",
        },
        _active: {
          shadow: "sm",
        },
        transition: "box-shadow 0.25s ease-in-out",
        borderTop: "2px solid rgba(254, 254, 254, 0.4)",
      },
      variants: {
        brand: {
          color: "cyan.900",
          bgGradient: "linear(to-bl,cyan.200,cyan.400)",
        },
      },
    },
  },
});

export default theme;
