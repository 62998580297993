import ky from "ky";

const covalent = ky.extend({
  prefixUrl: "https://api.covalenthq.com/v1/",
});

const API_KEY = "key=ckey_4f43826d38e04f32a46fe01f841";

export const fetchTokenPrice = async (tokenAddress) =>
  covalent
    .get(`pricing/historical_by_address/1/usd/${tokenAddress}/?${API_KEY}`)
    .json();

export const fetchTransfers = async (address, tokenAddress, limit = 100) =>
  covalent
    .get(
      `1/address/${address}/transfers_v2/?contract-address=${tokenAddress}&page-size=${limit}&${API_KEY}`
    )
    .json();

export const fetchBalances = async (address) =>
  covalent.get(`1/address/${address}/balances_v2/?${API_KEY}`).json();

export const fetchTXPrices = async (address, tokenAddress, txHashes) =>
  covalent
    .get(
      `1/address/${address}/transfers_v2/?contract-address=${tokenAddress}&{"transfers.0.tx_hash":{"$in":[${JSON.stringify(txHashes)}]}}&${API_KEY}`
    )
    .json();

export const fetchTopHolders = async (tokenAddress, limit = 10) =>
  covalent
    .get(
      `https://api.covalenthq.com/v1/1/tokens/${tokenAddress}/token_holders/?limit=${limit}&sort={"balance":-1}&${API_KEY}`
    )
    .json();
