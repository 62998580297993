import React, { useState } from "react";
import { Button, HStack, Input } from "@chakra-ui/react";
import { FiZap } from "react-icons/fi";
import { useAtom } from "jotai";
import { walletFetchingState } from "./store";

export default function InputWalletAddress({ defaultValue }) {
  let [address, setAddress] = useState(defaultValue);
  let [fetchState, doFetch] = useAtom(walletFetchingState)

  return (
    <HStack spacing={4}>
      <Input value={address} onChange={(e) => setAddress(e.target.value)} />
      <Button
        variant="brand"
        leftIcon={<FiZap />}
        isLoading={fetchState.isLoading}
        onClick={() => doFetch(address)}
      >
        Audit
      </Button>
    </HStack>
  );
}
