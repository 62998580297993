import React, { useMemo } from "react";
import Highcharts, { getHighchartsOptions } from "../../components/Highcharts";

export function BarChart({ data, title, seriesName }) {
  let options = useMemo(
    () => ({
      title: {
        text: title,
      },
      xAxis: {
        crosshair: true,
        categories: data.map((d) => d.date),
      },
      series: [
        {
          type: "column",
          name: seriesName,
          data: data.map((d) => d.value),
        },
      ],
    }),
    [data, title, seriesName]
  );

  return <Highcharts options={options} />;
}

export function BarLineChart({ data, title, barName, lineName }) {
  let options = useMemo(
    () => ({
      chart: {
        zoomType: "xy",
      },
      title: {
        text: title,
      },
      xAxis: {
        crosshair: true,
        categories: data.map((d) => d.date),
      },
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: barName,
            style: {
              color: getHighchartsOptions().colors[0],
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: lineName,
            style: {
              color: getHighchartsOptions().colors[1],
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      series: [
        {
          type: "column",
          name: barName,
          data: data.map((d) => d.value1),
        },
        {
          yAxis: 1,
          type: "line",
          name: lineName,
          data: data.map((d) => d.value2),
        },
      ],
    }),
    [data, title, barName, lineName]
  );

  return <Highcharts options={options} />;
}

export function StackBarChart({ data, title, name1, name2 }) {
  let options = useMemo(
    () => ({
      chart: {
        zoomType: "xy",
      },
      title: {
        text: title,
      },
      xAxis: {
        crosshair: true,
        categories: data.map((d) => d.date),
      },
      tooltip: {
        shared: true,
      },
      series: [
        {
          type: "column",
          name: name1,
          data: data.map((d) => d.value1),
        },
        {
          type: "column",
          name: name2,
          data: data.map((d) => d.value2),
        },
      ],
    }),
    [data, title, name1, name2]
  );

  return <Highcharts options={options} />;
}
