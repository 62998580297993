import React from "react";
import { Box } from "@chakra-ui/react";
import { StackBarChart } from "./Chart";
import { useAtomValue } from "jotai/utils";
import { tokenState } from "./store";

export default function Flow() {
  let { flow } = useAtomValue(tokenState);
  if (!flow) return null;

  return (
    <Box>
        <StackBarChart
          title="Monthly Unique Addresses"
          data={flow}
          name1="Senders"
          name2="Receivers"
        />
      </Box>
  );
}
